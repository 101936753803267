import React, { useState } from 'react';
import Confetti from 'react-confetti';
import './App.css';

function App() {
  const [esFeliz, setEsFeliz] = useState(false);

  const cambiarExpresion = () => {
    setEsFeliz(!esFeliz);
  };

  // Aplica la clase condicionalmente según el estado
  const claseFondo = esFeliz ? 'fondo-feliz' : 'fondo-triste';

  return (
    <div className={`contenedor ${claseFondo}`}>
      {esFeliz && <Confetti />}
      <div className="cara">
        {esFeliz ? '😊' : '😢'}
      </div>
      <button onClick={cambiarExpresion}>Cambiar Expresión</button>
    </div>
  );
}

export default App;
